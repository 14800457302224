import React, { Component } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Button, CloseButton } from "@instructure/ui-buttons";
import { DrawerLayout } from "@instructure/ui-layout";
import { ScreenReaderContent } from "@instructure/ui-a11y";
import { Mask } from "@instructure/ui-overlays";
import IconHamburger from "@instructure/ui-icons/lib/IconHamburgerSolid";
import { Link } from "gatsby";

import Header from "./header";
import Progress from "./progress";
import "./layout.css";
import styles from "./layout.module.css";
import UFCLogo from "../images/ufc-logo.svg";

export default class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isModule: PropTypes.bool.isRequired
  };

  state = {
    trayNavOpen: false,
    trayIsOverlayed: false
  };

  handleOverlayTrayChange = trayIsOverlayed => {
    this.setState({ trayIsOverlayed });
  };

  handleTrayDismiss = () => {
    this.setState({ trayNavOpen: false });
  };

  render() {
    const { children, isModule } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className={styles.layoutContainer}>
            {this.state.trayIsOverlayed && this.state.trayNavOpen && (
              <Mask onClick={this.handleTrayDismiss} />
            )}
            <DrawerLayout
              minWidth="40rem"
              onOverlayTrayChange={this.handleOverlayTrayChange}
            >
              <DrawerLayout.Tray
                id="MainNavigation"
                open={this.state.trayNavOpen}
                placement="start"
                label="Main Navigation Tray"
                onDismiss={this.handleTrayDismiss}
                onClose={() =>
                  this.menuOpenButton && this.menuOpenButton.focus()
                }
              >
                <CloseButton
                  placement="end"
                  offset="small"
                  variant="icon"
                  onClick={this.handleTrayDismiss}
                >
                  Close
                </CloseButton>
                <Header siteTitle={data.site.siteMetadata.title} />
              </DrawerLayout.Tray>
              <DrawerLayout.Content>
                <div>
                  <div className={styles.topLogoContainer}>
                    {!this.state.trayNavOpen && (
                      <Button
                        ref={c => (this.menuOpenButton = c)}
                        margin="x-small"
                        icon={IconHamburger}
                        variant="icon-inverse"
                        size="large"
                        onClick={() => {
                          this.setState({ trayNavOpen: true });
                        }}
                        aria-haspopup={
                          this.state.trayIsOverlayed ? "dialog" : "region"
                        }
                        aria-controls="MainNavigation"
                      >
                        <ScreenReaderContent>Close</ScreenReaderContent>
                      </Button>
                    )}
                    <Link className={styles.topLogo} to="/">
                      <span>
                        <UFCLogo
                          aria-label="Utah Foster Care"
                          title="Utah Foster Care"
                        />
                      </span>
                    </Link>
                  </div>

                  <div
                    className={
                      isModule
                        ? styles.moduleContentContainer
                        : styles.contentContainer
                    }
                  >
                    {isModule ? (
                      <>
                        <div className={styles.moduleContent}>{children}</div>
                        <div className={styles.moduleProgressContainer}>
                          <Progress />
                        </div>
                      </>
                    ) : (
                      <div>{children}</div>
                    )}
                  </div>
                </div>
              </DrawerLayout.Content>
            </DrawerLayout>
          </div>
        )}
      />
    );
  }
}
