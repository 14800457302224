import { Link } from "gatsby";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons";

import styles from "./header.module.css";
import UFCLogo from "../images/ufc-logo.svg";
import { setCurrentModule, setCurrentStep } from "../state/actions";

const NAV_ITEMS = [
  {
    to: "/modules/rights-and-responsibilities",
    text: "Rights & Responsibilities",
    moduleId: 3
  },
  {
    to: "/modules/respite-babysitting-daycare",
    text: "Respite, Babysitting, & Daycare",
    moduleId: 4
  },
  {
    to: "/modules/travel",
    text: "Travel",
    moduleId: 2
  },
  {
    to: "/modules/normalcy",
    text: "Normalcy",
    moduleId: 1
  },
  {
    to: "/modules/property-emergency-safe-to-sleep",
    text: "Property Damage, Emergency Situations, & Safe-to-sleep",
    moduleId: 5
  },
  {
    to: "/modules/reimbursements",
    text: "Reimbursements",
    moduleId: 6
  },
  {
    to: "/modules/advocacy-schools-court",
    text: "Advocacy, Schools, & Court",
    moduleId: 7
  },
  {
    to: "/modules/foster-family-supports",
    text: "Foster Family Supports",
    moduleId: 8
  },
  {
    to: "/modules/assessment-placement-conflict",
    text: "Assessment, Placement, & Conflict Resolution",
    moduleId: 9
  }
];

export class Header extends Component {
  static propTypes = {
    siteTitle: string,
    handleNavigate: func.isRequired,
    renderInIndex: bool
  };
  static defaultProps = {
    siteTitle: "",
    renderInIndex: false
  };

  render() {
    if (this.props.renderInIndex) {
      return (
        <nav className={styles.headerIndexContainer}>
          <h2 className={styles.header}>Learning Modules</h2>
          {NAV_ITEMS.map(item => (
            <Link
              className={styles.navigationListItemLink}
              activeClassName={styles.navigationListItemLinkActive}
              to={item.to}
              key={item.text}
              onClick={() => {
                this.props.handleNavigate(item.moduleId);
              }}
            >
              <div className={styles.linkTextContainer}>
                <span className={styles.linkText}>{item.text}</span>
                <span className={styles.completionStatus}>
                  {(this.props.modules.find(x => x.id === item.moduleId) || {})
                    .completed ? (
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon
                        icon={faSquare}
                        color="green"
                        size="2x"
                      />
                      <FontAwesomeIcon
                        icon={faCheck}
                        inverse
                        transform="shrink-7"
                        size="2x"
                      />
                    </span>
                  ) : null}
                </span>
              </div>
            </Link>
          ))}
        </nav>
      );
    }
    return (
      <nav className={styles.headerContainer}>
        <div className={styles.navigationItemContainer}>
          <h2 className={styles.header}>Learning Modules</h2>
          <ul className={styles.navigationList}>
            {NAV_ITEMS.map(item => (
              <li className={styles.navigationListItem} key={item.text}>
                <Link
                  className={styles.navigationListItemLink}
                  activeClassName={styles.navigationListItemLinkActive}
                  to={item.to}
                  onClick={() => {
                    this.props.handleNavigate(item.moduleId);
                  }}
                >
                  <div className={styles.linkTextContainer}>
                    <span className={styles.linkText}>{item.text}</span>
                    <span className={styles.completionStatus}>
                      {(
                        this.props.modules.find(x => x.id === item.moduleId) ||
                        {}
                      ).completed ? (
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon
                            icon={faSquare}
                            color="green"
                            size="2x"
                          />
                          <FontAwesomeIcon
                            icon={faCheck}
                            inverse
                            transform="shrink-7"
                            size="2x"
                          />
                        </span>
                      ) : null}
                    </span>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.logoContainer}>
          <Link to="/">
            <UFCLogo aria-label="Utah Foster Care" title="Utah Foster Care" />
          </Link>
        </div>
      </nav>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps,
  ...state
});
const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
      dispatch(setCurrentStep(1));
    }
  };
};

const ConnectedHeader = connect(
  stateToProps,
  dispatchToProps
)(Header);

export default ConnectedHeader;
