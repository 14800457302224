import { createActions } from "redux-actions";

export const {
  setCurrentModule,
  setCurrentStep,
  completeModule
} = createActions(
  {},
  "SET_CURRENT_MODULE",
  "SET_CURRENT_STEP",
  "COMPLETE_MODULE"
);

export const saveLocalStateToGlobalState = (state, information) => {
  return {
    type: "SAVE_LOCAL_STATE_TO_GLOBAL",
    payload: {
      localState: state,
      ...information
    }
  };
};
