import React, { Component } from "react";
import { number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "gatsby";
import { module } from "../shared/custom-proptypes";
import ReactMedia from "react-media";
import { ToggleDetails } from "@instructure/ui-toggle-details";
import "./progress.scss";

export class Progress extends Component {
  static propTypes = {
    modules: arrayOf(module),
    currentModule: module,
    currentModuleStep: number
  };

  renderProgressBar() {
    const { modules, currentModule, currentModuleStep } = this.props;

    const activeModule = modules.find(x => x.id === currentModule);
    return (
      <ul className="steps has-content-centered">
        {activeModule.steps.map(step => {
          const markerStyles = classnames({
            "is-hollow": step.id === currentModuleStep,
            "steps-marker": true
          });
          const segmentStyles = classnames({
            "steps-segment": true,
            "is-active": step.id === currentModuleStep,
            "has-gaps": step.id === currentModuleStep
          });
          return (
            <li key={step.id} className={segmentStyles}>
              <span className={markerStyles} />
              <div className="steps-content">
                <p className="is-size-6">
                  <Link to={`/modules/${step.link}`}>{step.title}</Link>
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  renderFacade() {
    const { modules, currentModule, currentModuleStep } = this.props;
    return (
      <div>
        <ToggleDetails
          summary={
            <div className="moduleFacadeContainer">
              <div className="moduleFacadeText">Module Progress</div>
              <div className="moduleFacadeStepCount">
                Current Step:{" "}
                {`${currentModuleStep} / ${
                  modules.find(x => x.id === currentModule).steps.length
                }`}
              </div>
            </div>
          }
          fluidWidth
        >
          {this.renderProgressBar()}
        </ToggleDetails>
      </div>
    );
  }

  render() {
    const { modules, currentModule } = this.props;

    const activeModule = modules.find(x => x.id === currentModule);
    if (activeModule) {
      return (
        <ReactMedia query={{ minWidth: "768px" }}>
          {matches =>
            matches ? this.renderProgressBar() : this.renderFacade()
          }
        </ReactMedia>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (
  { modules, currentModule, currentModuleStep },
  ownProps
) => ({
  ...ownProps,
  modules,
  currentModule,
  currentModuleStep
});

const mapDispatchToProps = dispatch => {
  return { increment: () => dispatch({ type: `INCREMENT` }) };
};

const ConnectedProgress = connect(
  mapStateToProps,
  mapDispatchToProps
)(Progress);

export default ConnectedProgress;
