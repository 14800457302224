import { number, shape, arrayOf, string, bool } from "prop-types";

export const module = shape({
  id: number,
  title: string,
  steps: arrayOf(
    shape({
      id: number,
      title: string,
      link: string,
      completed: bool
    })
  )
});
